import { useGetRecentColleagues } from "@src/appV2/Agents/api/useGetRecentColleagues";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { SHIFT_SLOTS_V2_PAGE_SIZE } from "@src/appV2/OpenShifts/constants";
import { useGetPaginatedBookabilityStatusForShifts } from "@src/appV2/OpenShifts/ShiftAction/api/useGetBookabilityStatusForShifts";
import { SHIFT_DISCOVERY_SHIFT_MODAL_PATH } from "@src/appV2/redesign/ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useShiftModalsDataContext";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import constate from "constate";
import { useRef } from "react";
import { type ListRange } from "react-virtuoso";

import { useGetFriendsList } from "../../WorkWithFriends/hooks/useGetFriendsList";
import { convertOpenShiftToModalShiftData } from "../convertOpenShiftToModalShiftData";
import type { Offer, OpenShift, OpenShiftWorkplace, RateNegotiation } from "./types";
import { type ShiftSlotCoalesced, useGetPaginatedShiftSlotsV2 } from "./useGetShiftsSlotV2";
import { useOpenShiftsMissingRequirements } from "./useOpenShiftsMissingRequirements";

interface OnClickOpenShiftParams {
  shift: OpenShift;
  workplace: OpenShiftWorkplace;
  offer?: Offer;
}

interface OpenShiftListData {
  openShifts: OpenShift[];
  workplacesMap: Map<string, OpenShiftWorkplace>;
  rateNegotiationsMap: Map<string, RateNegotiation>;
  canLoadMoreShifts?: boolean;
  isLoadingMoreShifts?: boolean;
  loadMoreShifts?: () => void;
  isOpenShiftsLoading?: boolean;
  isOpenShiftsError?: boolean;
  isOpenShiftsSuccess?: boolean;
}

/**
 * Encapsulates data for open shift lists, and logic for opening a shift modal.
 *
 * Fetches bookability status and missing requirements for given open shifts,
 * and provides them in a map of shift id to data, which is used by the
 * shift card component.
 *
 * Also provides data for loading more shifts, used by the virtualized list.
 *
 * When opening a shift modal, the function pre-populates the modal
 * with data so that it is ready as soon as it's opened.
 */
function useOpenShiftListData(props: OpenShiftListData) {
  const {
    openShifts,
    workplacesMap,
    rateNegotiationsMap,
    canLoadMoreShifts = false,
    isLoadingMoreShifts = false,
    loadMoreShifts,
    isOpenShiftsLoading,
    isOpenShiftsError,
    isOpenShiftsSuccess,
  } = props;

  const { userId: workerId, hideAsColleagueOnShifts } = useDefinedWorker();

  const { navigateToModal, setInitialShiftModalsData } = useShiftModalsDataContext();

  const lastThresholdRef = useRef<number>(0);
  const shiftsSlotsMapRef = useRef<Map<string, ShiftSlotCoalesced>>(new Map());
  const shiftsSlotsMap = shiftsSlotsMapRef.current;

  const {
    shiftBookabilityById,
    queryResult: {
      fetchNextPage: fetchNextBookabilityPage,
      hasNextPage: hasNextBookabilityPage,
      isFetchingNextPage: isFetchingBookabilityPage,
    },
  } = useGetPaginatedBookabilityStatusForShifts({
    shiftIds: openShifts.map((shift) => shift.id),
    agentId: workerId,
  });
  const shiftsBookabilityMap = new Map(Object.entries(shiftBookabilityById));

  const {
    shiftSlotsById,
    queryResult: { fetchNextPage: fetchNextShiftSlotsPage },
  } = useGetPaginatedShiftSlotsV2({
    shiftIds: openShifts.map((shift) => shift.id),
  });

  Object.entries(shiftSlotsById).forEach(([key, value]) => {
    shiftsSlotsMap.set(key, value);
  });

  const { data: friendsList } = useGetFriendsList();
  const friendsMap = new Map(friendsList?.data.map((friend) => [friend.id, friend]));

  const { data: recentColleaguesData } = useGetRecentColleagues({
    enabled: !hideAsColleagueOnShifts,
  });

  const recentColleaguesMap = new Map(
    recentColleaguesData?.map((colleague) => [colleague.colleagueUserId, colleague])
  );

  const shiftsMissingRequirementsMap = useOpenShiftsMissingRequirements({
    openShifts,
    workplacesMap,
  });

  const loadMore = () => {
    loadMoreShifts?.();
    void fetchNextBookabilityPage();
  };

  const handleRangeChanged = (range: ListRange) => {
    const currentThreshold =
      Math.ceil(range.startIndex / SHIFT_SLOTS_V2_PAGE_SIZE) * SHIFT_SLOTS_V2_PAGE_SIZE;
    if (currentThreshold > lastThresholdRef.current) {
      lastThresholdRef.current = currentThreshold;
      void fetchNextShiftSlotsPage();
    }
  };

  const canLoadMore = canLoadMoreShifts || hasNextBookabilityPage;
  const isLoadingMore = isLoadingMoreShifts || isFetchingBookabilityPage;

  const onClickOpenShift = (params: OnClickOpenShiftParams) => {
    const { shift, workplace, offer } = params;

    const rateNegotiation = rateNegotiationsMap.get(
      shift.relationships["rate-negotiation"]?.data.id ?? ""
    );

    const modalShiftData = convertOpenShiftToModalShiftData({
      shift,
      workplace,
      offer,
      rateNegotiation,
    });

    logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_OPENED, {
      shiftId: shift.id,
      shift: modalShiftData,
      bookabilityStatus: shiftsBookabilityMap.get(shift.id),
      missingRequirements: shiftsMissingRequirementsMap.get(shift.id),
    });

    setInitialShiftModalsData({
      shift: modalShiftData,
      bookabilityStatus: shiftsBookabilityMap.get(shift.id),
      shiftSlots: shiftsSlotsMap.get(shift.id),
      friendsMap,
    });

    navigateToModal(SHIFT_DISCOVERY_SHIFT_MODAL_PATH, { shiftId: shift.id });
  };

  return {
    canLoadMore,
    isLoadingMore,
    loadMore,
    onClickOpenShift,
    isOpenShiftsLoading,
    isOpenShiftsError,
    isOpenShiftsSuccess,
    workplacesMap,
    shiftsMissingRequirementsMap,
    shiftsBookabilityMap,
    shiftsSlotsMap,
    friendsMap,
    recentColleaguesMap,
    handleRangeChanged,
  };
}

export const [OpenShiftListDataProvider, useOpenShiftListDataContext] =
  constate(useOpenShiftListData);
